<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('pdw-personal-information',233)}}</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',253)}}</span>
            </div>
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',253)}}</span>
            </div>
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('pdw-personal-data',233)}}</div>

                    <app-row-left-label :slots="itemData.person_data.applicant_type != 2 ? [4,8] : [12,0]" class="mb-3">
                        <template v-slot:col-1>
                            <app-radio-group :label="trans('pdw-gender-question',233)" v-model="cItemData.person_data.sex" :options="sex" validatorRules="required" validatorName="Sex" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                        </template>
                        <template v-slot:col-2 v-if="itemData.person_data.applicant_type != 2">
                            <app-select :label="trans('pdw-what-martial-status',233)" v-model="cItemData.person_data.martial_status" type="getMartialStatus" :clearable="true" validatorRules="required" :validatorName="trans('pdw-what-martial-status',233)"/>
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :key="is_mobile.value" :slots="[4,2,6]">
                        <template v-slot:col-1>
                            <app-input :label="trans('pdw-first-name',233)" v-model="cItemData.person_data.first_name" validatorRules="required" validatorName="Voornaam" mask="lettersE1-20" />
                        </template>

                        <template v-slot:col-2>
                            <app-input :label="trans('pdw-middle-name',233)" v-model="cItemData.person_data.inbetween_name" mask="lettersE1-10" />
                        </template>

                        <template v-slot:col-3>
                            <app-input :label="trans('pdw-last-name',233)" v-model="cItemData.person_data.last_name" validatorRules="required" validatorName="Achternaam" mask="lettersE1-30" />
                        </template>
                    </app-row-left-label>

                    <app-row-left-label :slots="[4,8]">
                        <template v-slot:col-1>
                            <app-date :label="trans('pdw-enter-birth-date',233)" v-model="cItemData.person_data.birth_date" validatorRules="required|date-check|age-check" validatorName="Geboortedatum" />
                        </template>
                        
                        <template v-slot:col-2 >
                            <app-input :label="trans('pdw-enter-birth-place',233)" v-model="cItemData.person_data.birth_place" validatorRules="required" validatorName="Geboorteplaats" mask="lettersE1-25" />
                        </template>
                    </app-row-left-label>


                    <app-row-left-label :slots="[6,6] ">
                        <template v-slot:col-1>
                            <app-email :email.sync="cItemData.person_data.email" :label="trans('pdw-enter-email',233)" validatorRules="required" customSpinnerClass="app-local-input-spinner" @email-changed="onEmailChanged" :changeMode="true"/>
                        </template>

                        <template v-slot:col-2>
                            <label class="flabel mb-0"> {{trans('pdw-enter-phone-number',233)}} </label>
                             <app-phone :prefix.sync="cItemData.person_data.phone_prefix" :phone.sync="cItemData.person_data.phone_number" :isRequired="true" />
                        </template>  
                    </app-row-left-label>

                    <b-card v-if="showEmailAlert" no-body class="app-local-card-min-height app-card-warning mt-3">
                        <div class="app-info-card-label">
                            <span v-html="trans('pdw-email-exists',233)"></span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center flex-column mt-3">
                            <div class="app-store-buttons d-flex gap-3 mb-2">
                                <a href="https://play.google.com/store/apps/details?id=nl.happynurse.app" 
                                   class="store-link">
                                    <img src="https://content.app-us1.com/7RgW0/2023/05/09/c8b42623-a325-4bc6-b28c-39eca570b7c0.png" 
                                         alt="Play Store" 
                                         class="store-image">
                                </a>
                                <a href="https://apps.apple.com/nl/app/happyone/id1631900036" 
                                   class="store-link">
                                    <img src="https://content.app-us1.com/7RgW0/2023/05/09/fe92a0ed-9a8b-4585-a3bb-bf0617e1c6f2.png" 
                                         alt="App Store" 
                                         class="store-image">
                                </a>
                            </div>
                        </div>
                    </b-card>
            </b-card>

            <b-card class="mb-3">
                <div class="app-card-profile-title mb-3">{{trans('cdw-address-data',228)}}</div>
                <app-wizard-address :address.sync="cItemData.address" />
            </b-card>


            <b-card class="mb-3" v-if="cItemData.person_data.big_required == 1">
                <div class="app-card-profile-title mb-3">{{trans('pdw-big-registered',233)}}</div>
                <app-row-left-label :slots="[6,6]">
                    <template v-slot:col-1>
                        <div class="flabel">{{trans('pdw-big-registered-for-profession',233)}}</div>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group :options="big" v-model="cItemData.big.is_big_number" validatorRules="required" validatorName="Big"/>
                    </template>
                </app-row-left-label>
                <app-wizard-big v-if="cItemData.big.is_big_number" class="mt-3 mb-3" :bigData.sync="cItemData.big" />
            </b-card>

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

                
    </div>
</template>

<script>
import AppEmail from '@core/components/AppEmail.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppWizardAddress from '@core/components/Address/AppWizardAddress.vue';
import AppWizardBig from '@core/components/Big/AppWizardBig.vue';
import AppRadioGroup from '@core/components/AppRadioGroup.vue';
import { elevenproof,  date} from "@validations";

export default {
    components: {
        AppEmail,
        AppPhone,
        AppWizardAddress,
        AppWizardBig,
        AppRadioGroup
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading", "showEmailAlert"],

    created(){
        if(this.cItemData.person_data.id_martial_const_type){
            this.cItemData.person_data.martial_status = {name: this.cItemData.person_data.martial_name, value: this.cItemData.person_data.id_martial_const_type};
        }else{
            this.cItemData.person_data.martial_status = null
        }

        this.loadAddress()
    },
    
    mounted(){
        //window.addEventListener('resize', this.changeWindowSize);
        //this.is_mobile = screen.width < 660 || (screen.width < 1330 && screen.width > 767 ) ? true : false;
    },

    watch:{
        showSuccessAlert(){
            this.success_dismiss_count_down = this.dismiss_secs;
        },

        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    computed: {
         cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },

        cIbanLabel() {
            if(this.itemData.person_data.applicant_type == 1){//UZK
                return this.trans('pdw-enter-bank-account',233);
            }else if(this.itemData.person_data.applicant_type == 2){//ZZP
                return this.trans('pdw-enter-business-bank-account',233);
            }else{
                return this.trans('pdw-enter-iban',233);
            }
        }
    },

    data(){
        return{
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            sex: [
                { value: 1, text: this.trans('pdw-gender-male',233) },
                { value: 2, text: this.trans('pdw-gender-female',233) },
                { value: 3, text: this.trans('pdw-gender-neutral',233) }
            ],
            big: [
                { value: 1, text: this.trans('yes-option',253) },
                { value: 0, text: this.trans('no-option',253) },
            ],
            address: {
                post_code: null,
                city: null,
                house_number: null,
                house_number_additional: null,
                street: null,
                latitude: null,
                longitude: null,
                type: null,
                country: null
            },
            
        }
    },
    
    methods:{
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        },

        loadAddress(){
                this.address.post_code = this.cItemData.post_code;
                this.address.city = this.cItemData.city,
                this.address.house_number = this.cItemData.house_number,
                this.address.house_number_additional = this.cItemData.house_number_additional,
                this.address.street = this.cItemData.street,
                this.address.latitude = this.cItemData.latitude,
                this.address.longitude = this.cItemData.longitude,
                this.address.type = this.cItemData.address_type,
                this.address.country = {name: this.cItemData.country_name, value: this.cItemData.id_country_data_main};
        },

        changeWindowSize(){
            this.is_mobile = screen.width < 660 || (screen.width < 1330 && screen.width > 767 ) ? true : false;
        },

        onEmailChanged(email){
            this.$emit('email-changed', email);
        }
    }
};
</script>

<style scoped>
.app-local-card-min-height {
    padding: 1rem 1.5rem;
}

.store-image {
    width: 140px;
    height: 40px;
    transition: transform 0.2s;
}

.store-link:hover .store-image {
    transform: scale(1.05);
}

.gap-3 {
    gap: 2rem;
}
</style>
<style lang="scss">
</style>