<template>
    <div>
        <app-row-left-label class="mb-2" :slots="[6,6]" :isMarginBetween="false">
            <template v-slot:col-1>
                <app-input ref="ref_bsn" v-model="bsn" 
                                validatorName="BSN nummer" 
                                :validatorRules="'required|sofi-number'"  
                                v-mask="'#########'"
                                :loading="loading_bsn_check" 
                                :label="label" 
                                :additionalInfo="additionalInfo" 
                                :disabled="disabledControl" 
                                v-on:blur="checkBsnExist()"
                                v-on:change="change"
                                @change="onChange"
                                @input="onInput"
                                :customSpinnerClass="customSpinnerClass"
                                :currentError="currentError"
                        />
                        
                <!--<app-input ref="ref_bsn" type="hidden"  v-model="checked_bsn_key" name="bsn" validatorRules="required" :validatorCustomMessage="{ required: trans('email-exist-error',191) }"/>-->
            </template>
            <template v-slot:col-2>
                <b-card class="app-local-card-warning" v-if="ids_person != null && bsn != null && bsn != ''">
                    <div class="app-local-warning-text" v-html="cPersonMessage"></div>
                </b-card>
            </template>
        </app-row-left-label>     
    </div>
</template>
<script>
import axios from "@axios";
import envVariables from "/src/util/envVariables.js";

export default {

    components:{

    },

    props: {
        idPersonDataMain: [String, Number],
        bsnNumber: {type: String},
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        checkExist: { type: Boolean, default: true },
        currentError:{ type: Boolean, default: false},
        validatorName: { type: String },
        validatorRules: { type: String },
        label: {type: String},
        additionalInfo: {type: String},
        validatorCustomMessage: { type: Object },
        customSpinnerClass:{ type: String, default: "align-middle app-input-spinner"},
    },

    created(){
        this.bsn = this.bsnNumber;
    },

    mounted(){
        if(this.bsn != null && this.bsn != ''){
         this.checkBsnExist();
       }
    },

    computed: {
        cPersonMessage: {
            get() { 
                    
                    if(this.ids_person != null && JSON.parse(this.ids_person).length > 0){
                        let message = this.trans('fw-bsn-number-exist',177) + ' ';
                        let personUrl = this.envVariablesObj.getMainUrl() + '/people/person/profile/';

                        message += JSON.parse(this.ids_person).map(id => `<a href="${personUrl}${id}"><b>${id}</b></a>`).join(', ');
                        message += '. ';
                        return message;

                    }else{
                        return '';
                    }
                },
        }
    },

    data(){
        return{
            bsn: null,
            last_bsn_checked: '',
            core_bsn: this.bsnNumber,
            loading_bsn_check: false,
            checked_bsn_key: 'bsn',
            valid_last_bsn: true,
            ids_person: null,
            person_names: null,
            envVariablesObj: new envVariables(),
            disabledControl: this.disabled
        }
    },

    methods: {

        async checkBsnExist(){
            await this.$nextTick();
            
            this.$refs.ref_bsn.$refs.provider.validate().then((result) =>{
                
                if (!result.valid) {
                    return false;
                }else{
            
                    if(/*this.last_bsn_checked != this.bsn &&*/ this.bsn != null && this.bsn != '' && this.checkExist == true){
                        this.loading_bsn_check = true;
                        this.errorMessage={bsn: ''};
                        this.disabledControl = true;

                        this.ids_person = null;
                        this.person_names = null;

                        axios
                            .post("core/bsn/check", {
                                bsn_number: this.bsn,
                                id_person_data_main: this.idPersonDataMain
                            })
                            .then((res) => {

                                this.ids_person = res.data.ids_person;
                                this.person_names = res.data.person_names;
                                
                                this.last_bsn_checked = this.bsn;
                                this.$emit('checked');
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading_bsn_check = false;
                                this.disabledControl = false;
                        });
                        this.$refs.ref_bsn.$refs.provider.validate()
                    }

                }
            });

            
        },

        clearError(bsn){
            this.checked_bsn_key = 'bsn';
            if(this.last_bsn_checked == bsn && !this.valid_last_bsn){
                this.checked_bsn_key = '';
                this.ids_person = null;
                this.person_names = null;
            } 
            this.$refs.ref_bsn.$refs.provider.validate();
        },

        change(){
            this.$emit('update:bsnNumber', this.bsn);
            this.clearError(this.bsn) ;       
            
            this.ids_person = null;
            this.person_names = null;
        },

        onChange(){
            this.$emit('change', this.bsn);
        },

        onInput(){
            this.$emit('input', this.bsn);
        }

    }
    
}
</script>
<style scoped>
.app-local-card-warning{
    background-color: #fce7d4 !important;
}

.app-local-card-warning .app-local-warning-text{
    font-size: 14px;
}
</style>