<template>
    <ValidationObserver ref="observer" >
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="label" label-for="vi-mobile" :label-cols-md="label ? 4 : 0" class="app-local-input-label ">
                        <validation-provider v-slot="{ errors}" :rules="validatorRules" :name="label">
                            <b-input-group class="input-group-merge app-kvk" :class="{'app-kvk-error': ((checked_btw_key == '')  ||(errors.length > 0 && errors[0] != '')) && !disabled , 'app-kvk-disabled': disabled}">                            
                                <input id="BTW nummer" class="form-control form-control-sm app-kvk-input" :class="{'app-kvk-input-filled' : cBtwNumber && cBtwNumber.length > 0, 'app-kvk-input-error': ((checked_btw_key == '')  ||(errors.length > 0 && errors[0] != '')) && !disabled}" v-mask="'NL#########B##'" :disabled="disabled" v-model="cBtwNumber" v-on:blur="checkBtw" @change="changingBtw">
                                <b-input-group-append class="d-flex app-input-error-icon align-items-center app-kvk-input-error-icon" v-if="((checked_btw_key == '')  ||(errors.length > 0 && errors[0] != '')) && !disabled">
                                    <b-icon icon="exclamation-circle-fill"></b-icon>
                                </b-input-group-append>
                                <b-input-group-append class="app-kvk-append" :class="{'app-kvk-append-filled' : cBtwNumber && cBtwNumber.length > 0}">
                                    <b-button size="sm" text="Button" variant="transparent" @click="checkBtw(1)" class="app-input-button-group app-kvk-icon" :class="{'app-kvk-icon-filled' : cBtwNumber && cBtwNumber.length > 0, 'app-kvk-icon-error' : ((checked_btw_key == '')  ||(errors.length > 0 && errors[0] != '')) && !disabled}">
                                        <b-icon icon="arrow-clockwise" v-if="!loadingCheck" :class="{'app-kvk-icon-error' : ((checked_btw_key == '')  ||(errors.length > 0 && errors[0] != '')) && !disabled }"/>
                                        <b-spinner small class="align-middle" v-if="loadingCheck"></b-spinner>
                                    </b-button>
                                </b-input-group-append>                                
                            </b-input-group>
                             <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error">{{ errors[0] }}</small>
                        </validation-provider>
                        <app-input type="hidden" v-show="checkIfExists == false" v-model="checked_btw_key" name="KVK" validatorRules="required" :validatorCustomMessage="{ required: trans('invalid-number',256) }"/>
                    </b-form-group>
                </b-col>
             </b-row>

             <b-row>
                <b-col cols="12" v-if="f_information">
                    <b-form-group :label="label ? trans('information',257) : ''" label-for="vi-mobile" :label-cols-md="label ? 4 : 0" class="app-local-input-label align-items-center">                            
                        <b-col class="p-0">
                            <b-card class="app-card-info" v-if="persons_ids == null && cBtwData.btw_data_main">                                
                                <div class="font-weight-bolder kvk-data-inf-large">{{cBtwData.btw_data_main.name}}</div>
                                <div class="kvk-data-inf-large">{{cBtwData.btw_data_main.address }}</div>
                                <div class="kvk-data-inf-large" v-if="cBtwData.btw_data_main.traderName">{{cBtwData.btw_data_main.traderName }} {{cBtwData.btw_data_main.traderCompanyType }}</div>
                                <div class="kvk-data-inf-large"v-if="cBtwData.btw_data_main.traderPostalCode && cBtwData.btw_data_main.traderCity" >{{cBtwData.btw_data_main.traderCity }} {{cBtwData.btw_data_main.traderPostalCode }} {{cBtwData.btw_data_main.traderStreet }}</div>
                                <div class="kvk-data-inf-small">{{cBtwData.btw_check_date}}</div>
                            </b-card>
                            <b-card :class="'app-card-light'" v-if="persons_ids != null">
                                <span class="app-popover-text" v-html="trans('btw-already-used-error-p1',234)"></span>
                                <span>&nbsp;</span>
                                <span class="app-popover-text" v-if="persons_ids != null">{{trans('rt-flexworkers',234)}}: <b>{{ persons_names }}</b> {{trans('with-id',234)}} <b>{{ persons_ids }}</b></span>
                            </b-card>
                        </b-col>
                    </b-form-group>
                </b-col>
             </b-row>

           
    </ValidationObserver>
</template>

<script>
import axios from "@axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
components: {
    ValidationProvider,
    ValidationObserver,
},

props: {
    id_item: {type: Number, default: 0},
    btwData: {type: Object},
    name: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean, default: false },
    checkIfExists: { type: Boolean, default: false },
    validatorName: { type: String },
    validatorRules: { type: String },
    validatorCustomMessage: { type: Object },
    label:{type: String},
},

    created() {
        if (this.cBtwData.btw_number !== undefined && this.cBtwData.btw_number !== null && this.cBtwData.btw_data_main !== null) {
            this.f_information = true;
            this.checked_btw_number = this.cBtwData.btw_number;
            this.cBtwData.btw_data_main = JSON.parse(this.cBtwData.btw_data_main)
            this.basic_btw = JSON.parse(JSON.stringify(this.cBtwData));
        }
    },

computed: {
    computedShow: {
        get() { return this.show },
        set(value) { this.$emit('update:show', value) }, 
    },

    cBtwData: {
        get() { return this.btwData },
        set(value) { this.$emit('update:args', value) }
    },
    
    cReturn: {
        get() { return this.return },
        set(value) { this.$emit('update:return', value) }
    },
    cBtwNumber:{
        get() {return this.cBtwData.btw_number},
        set(value) { this.clearError(value), this.cBtwData.btw_number = value}
    }
},

data() {
    return {
        loading: false,
        f_information: false,
        f_Incorrect_number: false,
        checked_btw_key: "btw",
        checked_btw_number: "",
        basic_btw: null,
        loadingCheck: false,
        persons_ids: null,
        persons_names: null

    };
},

methods: {

  changingBtw() {
       this.f_Incorrect_number = false;
       this.cBtwData.btw_data_main = null;
       this.f_information = false;
       this.checked_btw_number = "";
   },

   checkBtw(refresh) {
        let token = this.$route.params.token;
        
        if (this.cBtwData.btw_number === undefined || this.cBtwData.btw_number === null || (this.cBtwData.btw_number !== null && this.cBtwData.btw_number.toString().length !== 14) || (this.checked_btw_number !== null && this.cBtwData.btw_number !== null && (refresh != 1 && this.checked_btw_number.toString() === this.cBtwData.btw_number.toString()))) {
            return;
        }

        this.cBtwData.btw_number = this.cBtwData.btw_number.toUpperCase()

        if(refresh != 1 && (this.basic_btw && this.basic_btw.btw_number == this.cBtwData.btw_number))  {
            this.f_information = true;
            this.checked_btw_key = "btw";  
            this.cBtwData.btw_data_main = this.basic_btw.btw_data_main;
            this.cBtwData.btw_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
            this.status = 1
            this.persons_ids = null;
            return;
        }
            this.loadingCheck = true;
            this.f_information = false;
            this.cBtwData.btw_data_main = null;
            refresh =  refresh == 1 ? 1 : 0;
            axios
                .get("core/btw/check/" + this.cBtwData.btw_number + "/" + refresh + "/" + this.id_item + "/" + token)
                .then((res) => {
                    if(res.data !== -1 && res.data !== -2) {
                        this.cBtwData.btw_data_main = res.data;
                        this.cBtwData.btw_check_date = moment().format('DD-MM-YYYY HH:mm:ss');
                        this.f_information = true;
                        this.f_Incorrect_number = false; 
                        this.checked_btw_key = "btw";    
                        this.persons_ids = res.data.persons_ids;
                        this.persons_names = res.data.persons_names; 
                    } else {
                        this.f_information = false;
                        this.checked_btw_key = "";
                        this.f_Incorrect_number = true; 
                        this.persons_ids = null;
                        this.persons_names = null; 
                    }

                    this.checked_btw_number = this.cBtwData.btw_number;
                    this.loadingCheck = false;
                    
                    
                })
                .catch((error) => {
                    this.loadingCheck = false;
                    console.error(`error during request: ${error}`);
                });  
                
            this.$refs.observer.validate();
    },

     clearError(btw){
        this.checked_btw_key = 'btw';
        if(this.checked_btw_number == btw && !this.f_Incorrect_number){
            this.f_information = true;
        }
        if(btw.toString().length != 14){
            this.f_information = false;
        }
        if((btw !== null 
                && btw.toString().length > 0 
                && btw.toString().length < 14) 
                || (this.f_Incorrect_number && btw !== null 
                    && btw.toString().length === 14)){
                         this.f_information = false;
                         this.checked_btw_key = '';
                    }
        if(this.$refs.observer != undefined){
            this.$refs.observer.validate();
        }
    },
},
};
</script>

<style scoped>
.kvk-data-inf-large{
font-size:14px
}

.kvk-data-inf-small{
font-size:12px
}
.app-local-input-label{
line-height: 1.2 !important;
padding-top:0px !important;
padding-bottom:0px !important;
}
.app-local-justify{
text-align:justify;
}
</style>
