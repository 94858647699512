<template>
    <div>
        <app-row-left-label class="mb-2" :slots=" [6,6]" :isMarginBetween="false">
            <template v-slot:col-1>
                <app-input :label="trans('cdw-enter-kvk',236)" type="integer" :disabled="disabled" :currentError="status == 2" :key="refresh" v-model="cApplicationData.kvk_number" validatorName="KVK nummer" validatorRules="required|digits:8" mask="########" @input="changingKvk()" @blur="checkKVK()"/>
                <app-select v-if="(loading || status == 1) && kvk.names_list && kvk.names_list.length > 1" :label="trans('fp-company-name',234)" v-model="kvk.trade_name" :options="kvk.names_list.map(item => item.naam)" @input="changeTradeName()"  @blur="checkKVK()"/>
            </template>
            <template v-slot:col-2>
                <app-row-left-label v-if="loading || status == 1" >            
                        <b-card no-body class="app-local-card-min-height  app-card-info">
                            <div class="app-info-card-label" v-if="loading">
                                <b-spinner class="app-local-spinner"/>{{trans('checking',253)}}
                            </div>
                            <div class="app-info-card-label" v-if="!loading && status === 1">
                                <label class="flabel">{{trans('kvk-data',257)}}</label>
                                <div v-if="kvk.names_list" class="font-weight-bolder kvk-data-inf-large">{{kvk.names_list.map(item => item.naam).join(' | ')}}</div>
                                <div> {{ kvk.street }} {{kvk.city}}</div>
                            </div>
                        </b-card>

                        <div @click="getMoreData()" class="app-local-offices">
                            <app-button type="cancel" class="p-0 app-local-text mt-2">
                                <b-link v-if="!loadingOffices" style=" cursor:pointer; outline:none !important;" class="mr-1">
                                    <b-icon icon="arrow-repeat" style="color: #adaeaf; width:15px; height:15px; margin-bottom:2px" />
                                </b-link> 
                                <b-spinner class="mr-1" v-else style="color: #adaeaf; width:12px; height:12px" small></b-spinner> 

                                <span>{{ trans('fp-view-more-offices', 234) }}</span>
                            </app-button>
                        </div>  
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 2" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('wrong-number',257)}}</label>
                            </div>
                        </b-card>
                </app-row-left-label>

                <app-row-left-label v-if="!loading && status == 3" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('kvk-is-busy',234)}}</label>
                            </div>
                        </b-card>
                </app-row-left-label>

               <app-row-left-label v-if="!loading && status == 4" >            
                        <b-card no-body class="app-local-card-min-height app-card-warning">
                            <div class="app-info-card-label">
                                <label class="flabel">{{trans('kvk-limitation',256)}}</label>
                            </div>
                        </b-card>
              </app-row-left-label>

            </template>
        </app-row-left-label>
    </div>
</template>
<script>
import axios from "@axios";

export default {

    components:{

    },

    props: {
        applicationData: {type: Object},
        name: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        idItem: {type: Number, default: 0},
        itemType: {type: Number, default: 0}
    },

    created(){
        this.showKVK();
        this.basic_offices = JSON.parse(this.cApplicationData.kvk_data_main)?.names_list ?? [];
        this.basic_sbi = JSON.parse(this.cApplicationData.kvk_data_main)?.sbiActiviteiten ?? [];
    },

    computed: {
        cApplicationData: {
            get() { return this.applicationData },
            ste(value) { this.$emit("update:applicationData", value)}
        }
    },

    watch:{
        "cApplicationData.kvk_data_main.trade_name": {
            handler: function(val) {
                if (!this.loadingOffices) {
                    this.getOfficeData();
                }                
            },
            deep: true,
        },
    },

    data(){
        return{
            status: 0,
            kvk: {
                trade_name: null,
                city: null,
                street: null,
                names_list: []
            },
            loading: false,
            clients_ids: null,
            persons_ids: null,
            refresh: 0,
            loadingOffices: false,
            basic_offices:[],
            basic_sbi: []
        }
    },

    methods: {

        checkKVK() {            
            if (this.cApplicationData.kvk_number === undefined || this.cApplicationData.kvk_number === null || this.cApplicationData.kvk_number.toString().length !== 8) {
                this.status = 0;
                this.kvk.trade_name = null;
                this.kvk.city = null;
                this.kvk.street = null;

                return;
            }
            
            this.loading = true;

            this.kvk.trade_name = null;
            this.kvk.city = null;
            this.kvk.street = null;

            axios
                .post("core/kvk/check", {
                    kvk_number: this.cApplicationData.kvk_number,
                    refresh: 0,
                    id_item: this.idItem,
                    item_type: this.itemType,
                    token: this.$route.params.token

                })
                .then((res) => {
                    if(res.data == -1){
                        this.status = 2;
                        this.cApplicationData.kvk_data_main = null;
                    }else if(res.data == -2){
                        this.status = 4;
                        this.cApplicationData.kvk_data_main = null;

                    }else{
                        

                        if(res.data.clients_ids != null || res.data.persons_ids != null){
                            this.clients_ids = res.data.clients_ids;
                            this.persons_ids = res.data.persons_ids;
                            this.cApplicationData.kvk_data_main = null;
                            this.status = 3;
                        }else{
                            
                            this.kvk = res.data;
                            this.cApplicationData.kvk_data_main = JSON.stringify(res.data);
                            this.status = 1;
                        }

                        this.basic_offices = JSON.parse(this.cApplicationData.kvk_data_main)?.names_list ?? [];
                        this.basic_sbi = JSON.parse(this.cApplicationData.kvk_data_main)?.sbiActiviteiten ?? [];
                    }
                })
                .catch((error) => {
                    console.error(`error during checking KVK: ${error}`);
                })
                .finally(() =>{
                    this.loading = false;
                });
        },

        changingKvk(){
            if(this.cApplicationData.kvk_number.length > 8){
                this.cApplicationData.kvk_number = this.cApplicationData.kvk_number.substring(0,8);
                this.refresh++;

                //check if mozilla firefox
                if(typeof InstallTrigger !== 'undefined'){
                    this.checkKVK();
                }
            }
        },

        showKVK() {
            if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main !== null) {
                this.kvk = JSON.parse(this.applicationData.kvk_data_main)

                this.status = 1;
                this.loading = false;
            } else if (this.cApplicationData.kvk_number !== undefined && this.cApplicationData.kvk_number !== null && this.cApplicationData.kvk_data_main === null) {
                this.status = 2;
            } else {
                this.status = 0;
            }
        },

        changeTradeName(){
            this.cApplicationData.kvk_data_main = JSON.stringify(this.kvk);
        },

        getMoreData(){
            this.loadingOffices = true;

            axios
                .post("core/kvk/getMoreData", {
                    kvk_number: this.cApplicationData.kvk_number,
                    token: this.$route.params.token

                })
                .then((res) => {
                    if(res.data == -2){
                        this.status = 4;
                        this.cApplicationData.kvk_data_main = null;

                    }else{
                        let new_offices = res.data
                        let office_names = JSON.parse(JSON.stringify(this.basic_offices));

                        if(new_offices.length > 0){
                            new_offices.forEach(item => {
                                const maxIndex = Math.max(...office_names.map(item => item.volgorde));
                                const exists = office_names.some(firstItem => firstItem.naam === item.naam);
                                
                                if (!exists) {
                                    office_names.push({
                                        naam: item.naam,
                                        volgorde: maxIndex+1,
                                        id_office: item.id_office
                                    });
                                }else{
                                    const existingOffice = office_names.find(firstItem => firstItem.naam === item.naam);
                                    existingOffice.id_office = item.id_office ? item.id_office : null
                                }
                            });

                            this.kvk.names_list = office_names
                            this.loadingOffices = false;
                        }
                    }
                })
                .catch((error) => {
                    this.loadingCheck = false;
                    console.error(`error during request: ${error}`);
                });  
        },

        getOfficeData(){
            let names_list = JSON.parse(this.cApplicationData.kvk_data_main)?.names_list ?? null;
            let id_office = null;
            if(names_list){
                id_office = names_list.find(item => item.naam === this.kvk.trade_name)?.id_office ?? null;
            }

            if(id_office){
                this.loadingOffices = true;
                axios
                    .post("core/kvk/getOfficeData", {
                        id_office: id_office,
                        token: this.$route.params.token
                    })
                    .then((res) => {
                        let parsedData = JSON.parse(this.cApplicationData.kvk_data_main);
                        parsedData.sbiActiviteiten = res.data.sbiActiviteiten;
                        this.cApplicationData.kvk_data_main = JSON.stringify(parsedData);
                        this.loadingOffices = false;
                    })
                    .catch((error) => {
                        this.loadingCheck = false;
                        console.error(`error during request: ${error}`);
                    });  
            }else{
                this.loadingCheck = false;
            }
        }

    }
    
}
</script>
<style scoped>
.app-local-card-min-height {
    min-height: 96px;
    padding: 1rem 1.5rem !important;
}

.app-local-spinner {
    width:16px; 
    height:16px; 
    margin-right:10px;
    font-size: 11px;
}

.app-local-offices{
    text-align: right;
}
</style>