<template>
    <div>
        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-debtors', 234) }}</label>
            </template>
            <template v-slot:col-2>
                <app-radio-group v-model="itemData.zzp_company_features.question_1" :options="answers"
                    :validatorName="trans('pdw-debtors', 234)" validatorRules="required" @change="checkCompany" />
            </template>
        </app-row-left-label>
        <small class="text-muted">{{ trans('pdw-debtors-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-legal', 234) }}</label>
            </template>
            <template v-slot:col-2>
                <app-radio-group v-model="itemData.zzp_company_features.question_2" :options="answers"
                    :validatorName="trans('pdw-legal', 234)" validatorRules="required" @change="checkCompany" />
            </template>
        </app-row-left-label>
        <small class="text-muted">{{ trans('pdw-legal-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-disease', 234) }}</label>
            </template>
            <template v-slot:col-2>
                <app-radio-group v-model="itemData.zzp_company_features.question_3" :options="answers"
                    :validatorName="trans('pdw-disease', 234)" validatorRules="required" @change="checkCompany" />
            </template>
        </app-row-left-label>
        <small class="text-muted">{{ trans('pdw-disease-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-entrepreneur', 234) }}</label>
            </template>
            <template v-slot:col-2>
            </template>
        </app-row-left-label>
        <app-memo v-model="itemData.zzp_company_features.question_4" :lettersCounter="true" :maxLength="500" rows="3" />
        <small class="text-muted">{{ trans('pdw-entrepreneur-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-acquisition', 234) }}</label>
            </template>
            <template v-slot:col-2>
            </template>
        </app-row-left-label>
        <app-memo v-model="itemData.zzp_company_features.question_5" :lettersCounter="true" :maxLength="500" rows="3" />
        <small class="text-muted">{{ trans('pdw-acquisition-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-tax', 234) }}</label>
            </template>
            <template v-slot:col-2>
                <app-radio-group v-model="itemData.zzp_company_features.question_6" :options="answers"
                    :validatorName="trans('pdw-tax', 234)" validatorRules="required" @change="checkCompany" />
            </template>
        </app-row-left-label>
        <small class="text-muted">{{ trans('pdw-tax-info', 234) }}</small>

        <hr class="mt-1 mb-2" />

        <app-row-left-label :slots="[9, 3]" :isMarginBetween="true">
            <template v-slot:col-1>
                <label class="app-input-top-label font-weight-normal">{{ trans('pdw-clients', 234) }}</label>
            </template>
            <template v-slot:col-2>

            </template>
        </app-row-left-label>
        <b-row>
            <b-col>
                <b-row v-if="cItemData && cItemData.zzp_company_features.question_7"
                    v-for="(c, index) in itemData.zzp_company_features.question_7.length"
                    class="d-flex align-items-center mb-1">
                    <b-col class="pr-0 mr-0">
                        <app-input v-model="itemData.zzp_company_features.question_7[index].name"
                            :label="index == 0 ? 'Naam opdrachtgever' : ''" />
                    </b-col>
                    <b-col>
                        <app-input v-model="itemData.zzp_company_features.question_7[index].function"
                            :label="index == 0 ? 'Functie' : ''" />
                    </b-col>
                    <div class="pl-0 ml-0 mr-3"
                        :class="itemData.zzp_company_features.question_7.length > 1 ? '' : 'input-margin-local'">
                        <app-button v-if="itemData.zzp_company_features.question_7.length > 1" type="secondary-grey"
                            class="app-local-button" :class="index == 0 ? 'mt-4' : ''" @click="removeString(index)">
                            <b-icon icon="dash" class="list-button-icon"></b-icon>
                        </app-button>
                    </div>
                </b-row>
                <b-row v-if="cItemData && cItemData.zzp_company_features.question_7" class="float-right"
                    :class="this.itemData.zzp_company_features.question_7.length < 1 ? 'mr-0' : 'mr-4'">
                    <app-button type="cancel" @click="addNextString"><b-icon icon="plus-circle" />
                        <span>Toevoegen</span></app-button>
                </b-row>
            </b-col>
        </b-row>
        <small class="text-muted">{{ trans('pdw-clients-info', 234) }}</small>
    </div>
</template>

<script>

export default {

    props: {
        itemData: Object,
        refresh_company_error: {}
    },

    components: {

    },

    created() {

    },

    mounted() {

    },

    computed: {
        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },

        cRefreshCompanyError: {
            get() { return this.refresh_company_error },
            set(value) { this.$emit("update:refresh_company_error", value) }
        },
    },

    watch: {

    },

    data() {
        return {
            email_phone: null,
            email_phone_error: false,
            answers: [
                { value: 1, text: 'Ja' },
                { value: 2, text: 'Nee' },
            ],
        }
    },

    methods: {
        checkCompany() {
            if (this.cItemData && this.cItemData.zzp_company_features &&
                (this.cItemData.zzp_company_features.question_1 == null ||
                    this.cItemData.zzp_company_features.question_2 == null ||
                    this.cItemData.zzp_company_features.question_3 == null ||
                    this.cItemData.zzp_company_features.question_6 == null)) {
                this.cRefreshCompanyError++;
                this.cItemData.zzp_company_features_status = 0;
            } else {
                this.cItemData.zzp_company_features.question_7 = this.cItemData.zzp_company_features.question_7.filter(e => {
                    return e.name && e.function;
                });
                if (this.cItemData.zzp_company_features.question_7.length == 0) {
                    this.cItemData.zzp_company_features.question_7 = [{ name: '', function: '' }];
                }
                this.cItemData.zzp_company_features_status = 1;
            }
        },

        addNextString() {
            this.cItemData.zzp_company_features.question_7.push({ name: '', function: '' });
        },

        removeString(index) {
            this.cItemData.zzp_company_features.question_7.splice(index, 1);
        }
    }

}
</script>
<style scoped>
.app-local-margin {
    margin-top: -25px !important;
}

.app-local-button {
    opacity: 1 !important;
    border: solid 1px #D0D5DD !important;
    border-radius: 0.25rem !important;
    line-height: 1rem !important;
    font-size: 1rem !important;
    color: #066791 !important;
    max-height: 31.3px !important;
    min-height: 31.3px !important;
    width: 30px !important;
    padding: 0 !important;
}

.app-local-button:hover {
    border-color: #066791 !important;
    box-shadow: 0px 0px 5px -2px rgba(13, 126, 179, 1);
}

.input-margin-local {
    margin-right: 30px;
}
</style>