<template>
    <div>
        
        <div class="navbar-inner">

            <div>
                <h1>Stap {{ itemData.step }}</h1>
                <h4 class="subheader">{{trans('pdw-enter-personal-information',258)}}</h4>
            </div>
            
            <div class="step" v-bind:class="{ 'step-done': itemData.step > 1, 'step-active': itemData.step === 1 }">
                <div>
                    <div class="circle" v-if="itemData.step > 1"><b-icon icon="check2"></b-icon></div>
                    <div class="circle" v-else>1</div>
                </div>
                <div>
                    <div class="title" v-bind:class="{ 'title-active': itemData.step === 1 }">{{trans('pdw-personal-information',233)}}</div>
                </div>
            </div>

            <div class="step" v-bind:class="{ 'step-done': itemData.step > 2, 'step-active': itemData.step === 2, 'step-with-substep': itemData.applicant_type > 0 }">
                <div>
                    <div class="circle" v-if="itemData.step > 2"><b-icon icon="check2"></b-icon></div>
                    <div class="circle" v-else>2</div>
                </div>
                <div>
                    <div class="title" v-bind:class="{ 'title-active': itemData.step === 2 }">{{trans('pdw-required-data',226)}}</div>
                </div>
            </div>

            <div v-if="itemData.step === 2">
                <!-- ID FOR UZK-->
                <div class="substep" v-if="itemData.applicant_type === 1 " v-bind:class="{ 'substep-done': (itemData.document_id.status === 1 && itemData.document_type > 0 && itemData.bsn_number != null && itemData.bsn_number != '') }">
                    <div>
                        <div class="circle"><b-icon v-if="(itemData.document_id.status === 1 && itemData.document_type > 0 && itemData.bsn_number != null && itemData.bsn_number != '')" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-id-card',234)}}</div>
                    </div>
                </div>
                
                <!-- BANK DATA -->
                <div class="substep" v-if="itemData.applicant_type === 1 || itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.iban != null && validatorElevenProof(itemData.iban) }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.iban != null && validatorElevenProof(itemData.iban)" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-bank-details',234)}}</div>
                    </div>
                </div>

                <!-- DIPLOMA -->
                <div class="substep" v-if="itemData.applicant_type > 0" v-bind:class="{ 'substep-done': itemData.document_diploma.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_diploma.status === 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-diplomas',234)}}</div>
                    </div>
                </div>

                <!-- VOH -->
                <div class="substep" v-if="itemData.applicant_type > 0" v-bind:class="{ 'substep-done': (itemData.document_verklaring.status === 1 || itemData.is_vog_document == 0) }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_verklaring.status === 1 || itemData.is_vog_document == 0" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-declaration-of-conduct',234)}}</div>
                    </div>
                </div>
                
                <!-- CV FOR UZK -->
                <div class="substep" v-if="itemData.applicant_type === 1" v-bind:class="{ 'substep-done': itemData.document_cv.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_cv.status === 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">CV</div>
                    </div>
                </div>

                <!-- REFERENCE FOR UZK -->
                <div class="substep circle-optional" v-if="itemData.applicant_type === 1" v-bind:class="{ 'substep-done': itemData.person_reference.first_name != null && itemData.person_reference.first_name.length > 0 && itemData.person_reference.last_name != null && itemData.person_reference.last_name.length > 0 && itemData.person_reference.function != null && itemData.person_reference.function.length > 0 && itemData.person_reference.organisation != null && itemData.person_reference.organisation.length > 0 && itemData.person_reference.phone_number != null && itemData.person_reference.phone_number.length > 8 && itemData.person_reference.email != null && itemData.person_reference.email.length > 0 && this.itemData.person_reference.email.match(this.email_regex)}">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.person_reference.first_name != null && itemData.person_reference.first_name.length > 0 && itemData.person_reference.last_name != null && itemData.person_reference.last_name.length > 0 && itemData.person_reference.function != null && itemData.person_reference.function.length > 0 && itemData.person_reference.organisation != null && itemData.person_reference.organisation.length > 0 && itemData.person_reference.phone_number != null && itemData.person_reference.phone_number.length > 8 && itemData.person_reference.email != null && itemData.person_reference.email.length > 0 && this.itemData.person_reference.email.match(this.email_regex)" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-reference',234)}}</div>
                    </div>
                </div>

                <!-- Geschillencommissie FOR ZZP -->
                <div class="substep" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.document_disputes.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_disputes.status == 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-dispute-commission',234)}}</div>
                    </div>
                </div>

                <!-- Beroepsaansprakelijkheid FOR ZZP -->
                <div class="substep" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.document_liability.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_liability.status == 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-professional-liability',234)}}</div>
                    </div>
                </div>

                <!-- COMPANY FOR ZZP-->
                <div class="substep" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.zzp_company_features_status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.zzp_company_features_status == 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">Ondernemersprofiel</div>
                    </div>
                </div>

                <!-- CV FOR ZZP -->
                <div class="substep" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.document_cv.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_cv.status === 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">CV</div>
                    </div>
                </div>

                <!-- Referentie FOR ZZP -->
                <div class="substep circle-optional" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.person_reference.first_name != null && itemData.person_reference.first_name.length > 0 && itemData.person_reference.last_name != null && itemData.person_reference.last_name.length > 0 && itemData.person_reference.function != null && itemData.person_reference.function.length > 0 && itemData.person_reference.organisation != null && itemData.person_reference.organisation.length > 0 && itemData.person_reference.phone_number != null && itemData.person_reference.phone_number.length > 8 && itemData.person_reference.email != null && itemData.person_reference.email.length > 0 && this.itemData.person_reference.email.match(this.email_regex)}">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.person_reference.first_name != null && itemData.person_reference.first_name.length > 0 && itemData.person_reference.last_name != null && itemData.person_reference.last_name.length > 0 && itemData.person_reference.function != null && itemData.person_reference.function.length > 0 && itemData.person_reference.organisation != null && itemData.person_reference.organisation.length > 0 && itemData.person_reference.phone_number != null && itemData.person_reference.phone_number.length > 8 && itemData.person_reference.email != null && itemData.person_reference.email.length > 0 && this.itemData.person_reference.email.match(this.email_regex)" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-reference',234)}}</div>
                    </div>
                </div>

                <!-- Kwaliteitsregister FOR ZZP -->
                <div class="substep substep-optional circle-optional" v-if="itemData.applicant_type === 2" v-bind:class="{ 'substep-done': itemData.document_quality.status === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_quality.status === 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title title-done">{{trans('pdw-quality-register',234)}}</div>
                    </div>
                </div>

                <!-- Certificaten -->
                <div class="substep circle-optional-last" v-if="itemData.applicant_type > 0" v-bind:class="{ 'substep-done': itemData.document_certificate.status === 1, 'substep-optional': itemData.applicant_type === 1 }">
                    <div>
                        <div class="circle"><b-icon v-if="itemData.document_certificate.status == 1" icon="check2" /></div>
                    </div>
                    <div>
                        <div class="title">{{trans('pdw-certificates',234)}}</div>
                    </div>
                </div>
            </div>

            <div class="step" v-bind:class="{ 'step-done': itemData.step > 3, 'step-active': itemData.step === 3 }">
                <div>
                    <div class="circle" v-if="itemData.step > 3"><b-icon icon="check2" /></div>
                    <div class="circle" v-else>3</div>
                </div>
                <div>
                    <div class="title" v-bind:class="{ 'title-active': itemData.step === 3 }">{{trans('pdw-risky-acts',235)}}</div>
                </div>
            </div>
            
        </div>
        
    </div>
</template>
<script>

export default {
    components: {},

    props: ["itemData"],

    created(){},

    computed: {},
    
    data() {
        return {
            email_regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        };
    },

    methods: {

        validatorElevenProof(value) {
            value = value.toUpperCase().replace(/\s/g, "");
            let rearrange = value.substring(4, value.length) + value.substring(0, 4);
            let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let alphaMap = {};
            let number = [];
            const codeLengths = {
                AD: 24,
                AE: 23,
                AL: 28,
                AT: 20,
                AZ: 28,
                BA: 20,
                BE: 16,
                BG: 22,
                BH: 22,
                BR: 29,
                CH: 21,
                CR: 21,
                CY: 28,
                CZ: 24,
                DE: 22,
                DK: 18,
                DO: 28,
                EE: 20,
                ES: 24,
                LC: 30,
                FI: 18,
                FO: 18,
                FR: 27,
                GB: 22,
                GI: 23,
                GL: 18,
                GR: 27,
                GT: 28,
                HR: 21,
                HU: 28,
                IE: 22,
                IL: 23,
                IS: 26,
                IT: 27,
                JO: 30,
                KW: 30,
                KZ: 20,
                LB: 28,
                LI: 21,
                LT: 20,
                LU: 20,
                LV: 21,
                MC: 27,
                MD: 24,
                ME: 22,
                MK: 19,
                MR: 27,
                MT: 31,
                MU: 30,
                NL: 18,
                NO: 15,
                PK: 24,
                PL: 28,
                PS: 29,
                PT: 25,
                QA: 29,
                RO: 24,
                RS: 22,
                SA: 24,
                SE: 24,
                SI: 19,
                SK: 24,
                SM: 27,
                TN: 24,
                TR: 26,
            };
            const iban = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
            const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
            if (!code || iban.length !== codeLengths[code[1]]) {
                return false;
            }

            alphabet.forEach((value, index) => {
                alphaMap[value] = index + 10;
            });
            rearrange.split("").forEach((value, index) => {
                number[index] = alphaMap[value] || value;
            });
            if (this.modulo(number.join("").toString(), 97) === 1) {
                return true;
            } else {
                return false;
            }
        },
         modulo(aNumStr, aDiv) {
            var tmp = "";
            var i, r;
            for (i = 0; i < aNumStr.length; i++) {
                tmp += aNumStr.charAt(i);
                r = tmp % aDiv;
                tmp = r.toString();
            }
            return tmp / 1;
        },
    },

   

};
</script>

<style scoped>
/* Steps */
.step {
    position: relative;
    min-height: 62px;
    color: #0D7BB3;
}
.step + .step {
    /*margin-top: 1.5em*/
}
.step > div:first-child {
    position: static;
    height: 0;
}
.step > div:not(:first-child) {
    margin-left: 3.5em;
    padding-left: 1em;
}

.step.step-done {
    color: #0D7BB3;
}
.step.step-done .circle {
    background-color: #8DD7F7;
    border: solid 1px #8DD7F7;
    color: #fff;
    font-size: 5px;
}

.step.step-active {
    color: #0D7BB3;
}
.step.step-active .circle {
    background-color: #0D7BB3;
    color: #fff;
    font-weight: 500;
}

.step.step-active.step-with-substep {
    min-height: 60px;
}

/* Circle */

.step .circle {
    background: #fff;
    position: relative;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    border: solid 1px #0D7BB3;
    color: #0D7BB3;
    text-align: center;
    font-size: 21px;
    font-weight: normal;
}

/* Vertical Line */
.step .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100px;
    width: 1px;
    border: 1px dotted #0D7BB3;
    z-index: -1;
}

.step:last-child .circle:after {
    display: none;
}

.step .title {
    line-height: 1.5em;
    font-weight: normal;
    font-size: 14px;
    padding-top: 10px;
    color: #000;
}

.step .title-active {
    font-weight: bold;
    color: #174066;
}

.step .b-icon {
    height: 21px;
    width: 21px;
    stroke-width: 5;
}

.substep .b-icon {
    height: 18px;
    width: 18px;
    stroke-width: 5;
}

/* substep */
.substep {
    position: relative;
    min-height: 40px;
    color: #0D7BB3;
}

.substep.substep-optional {
    min-height: 90px;
    padding-top: 50px;
}

.substep + .substep {
    /*margin-top: 1.5em*/
}
.substep > div:first-child {
    position: static;
    height: 0;
}
.substep > div:not(:first-child) {
    margin-left: 3.5em;
    padding-left: 1em;
}

.substep.substep-done {
    color: #0D7BB3;
}
.substep.substep-done .circle {
    background-color: #8DD7F7;
    border: solid 1px #8DD7F7;
    color: #fff;
    font-size: 5px;
}

.substep.substep-active {
    color: #0D7BB3;
}
.substep.substep-active .circle {
    background-color: #0D7BB3;
    color: #fff;
    font-weight: 500;
}

.substep .circle {
    background: #fff;
    position: relative;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 100%;
    border: solid 1px #0D7BB3;
    color: #0D7BB3;
    text-align: center;
    font-size: 21px;
    font-weight: normal;
    margin-left: 5px;
}

/* Vertical Line */
.substep .circle:after {
    content: " ";
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 40px;
    width: 1px;
    border: 1px dotted #0D7BB3;
    z-index: -1;
}

.substep.circle-optional .circle:after {
    height: 100px;
}

.substep.circle-optional-last .circle:after {
    height: 50px;
}

/*.substep:last-child .circle:after {
    display: none;
}*/

.substep .title {
    line-height: 1.5em;
    font-weight: normal;
    font-size: 12px;
    padding-top: 5px;
    color: #1f627f;
}

.substep .title-active {
    font-weight: bold;
    color: #1f627f;
}

.substep .feather {
    height: 18px;
    width: 18px;
    stroke-width: 2;
}

.step:hover,
.substep:hover {
    /*background-color: rgb(207, 207, 207); 
  cursor: pointer;*/
}
</style>
