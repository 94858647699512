<template>
    <div>
        
        <h1 class="mt-2 mb-4">{{trans('pdw-required-data',234)}}</h1>

        <b-alert variant="success" :show="success_dismiss_count_down" @dismiss-count-down="countDownChangedSuccess" fade >
            <div>
                    <b-icon icon="check-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('successfully-saved',253)}}</span>
            </div>
            
        </b-alert>

        <b-alert variant="danger" :show="unsuccess_dismiss_count_down" @dismiss-count-down="countDownChangedUnsuccess" fade >
            <div>
                    <b-icon icon="x-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    <span class="align-middle">{{trans('unsuccessfull-save',253)}}</span>
            </div>
            
        </b-alert>

        <b-overlay :show="loading" :opacity="0.0">

            <people-applicants-profile-wizard-uzk-component :itemData="itemData" v-if="itemData.applicant_type === 1" />
            <people-applicants-profile-wizard-zzp-component :itemData="itemData" v-else-if="itemData.applicant_type === 2" />

            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
         
    </div>
</template>

<script>

import PeopleApplicantsProfileWizardUzkComponent from './Components/PeopleApplicantsProfileWizardUzkComponent.vue';
import PeopleApplicantsProfileWizardZzpComponent from './Components/PeopleApplicantsProfileWizardZzpComponent.vue';

export default {
    components: {
        PeopleApplicantsProfileWizardUzkComponent,
        PeopleApplicantsProfileWizardZzpComponent,
    },

    props: ["itemData", "showSuccessAlert", "showUnsuccessAlert", "loading", "loadingPartial"],

    watch:{
        showSuccessAlert(){
            if(this.save_change_appication_type == false){
                this.success_dismiss_count_down = this.dismiss_secs;
            }else{
                this.save_change_appication_type = false;
            }
            
        },
        
        showUnsuccessAlert(){
            this.unsuccess_dismiss_count_down = this.dismiss_secs;
        }
    },

    computed:{
       cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }, 
        },
    },

    data() {
        return {
            dismiss_secs: 3,
            success_dismiss_count_down: 0,
            unsuccess_dismiss_count_down: 0,
            save_change_appication_type: false
        }
    },

    methods: {
        
        countDownChangedSuccess(success_dismiss_count_down) {
            this.success_dismiss_count_down = success_dismiss_count_down;
        },

        countDownChangedUnsuccess(unsuccess_dismiss_count_down){
            this.unsuccess_dismiss_count_down = unsuccess_dismiss_count_down;
        }
    },


};
</script>
<style>
.b-selected {
    filter: brightness(0) invert(1);
}

.btn-outline-secondary{
    color: #1b3f68;
}

.mb-xs-3{
    margin-bottom: 1rem;
}
</style>
