<template>
    <div>
        <div v-if="!loading">
            <!--SIDE BAR-->
            <side-bar v-if="step < 4">
                <template slot="links">
                    <people-data-wizard-left-side-bar-component :itemData.sync="item_data" />
                </template>    
            </side-bar>

            <!--- MAIN CONTENT -->
            <div class="main-content">
                <fade-transition :duration="200" origin="center top" mode="out-in">
                    <b-row> 
                        <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                            <div class="mt-3 mb-3 d-flex align-items-center" v-if="step > 0 && step < 4">
                                <b-link v-if="step > 1" href="#" @click="goBack" class="d-flex align-items-center"><b-icon icon="chevron-left" font-scale="1" class="mr-2"></b-icon>{{trans('previous-step',253)}}</b-link>
                            </div>

                            <ValidationObserver ref="form">
                                <form @submit.prevent="onSubmit">
                                    <content-step-1 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-if="step === 1" :showEmailAlert="show_email_alert" @email-changed="checkEmail"/>
                                    <content-step-2 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-else-if="step === 2"/>
                                    <content-step-3 :itemData.sync="item_data" :showSuccessAlert="show_success_alert" :showUnsuccessAlert="show_unsuccess_alert" :loading="loading" v-else-if="step === 3" />
                                    <content-step-4 :itemData.sync="item_data" :loading="loading_next_step" v-if="step === 4" />
                                </form>
                            </ValidationObserver>

                            <div v-if="loading_partial === false && step !== 4" class="mt-4 mb-3" >
                                <app-button @click="saveStep(0)" type="cancel" :disabled="loading">{{trans('save',255)}}</app-button>
                                <app-button @click="nextStep()" :disabled="loading == true" :loading="loading">{{trans('continue',255)}}</app-button>
                            </div>

                        </b-col>
                    </b-row>
                </fade-transition>
            </div>
        </div>
        
        <!-- LOADER HAPPY NURSE -->
        <b-row v-if="loading">
            <app-loader />
        </b-row>

    </div>
</template>
<script>

import { FadeTransition } from 'vue2-transitions';
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppLoader from '@core/components/AppLoader.vue';
import PeopleDataWizardLeftSideBarComponent from './PeopleDataWizardLeftSideBarComponent.vue';
import ContentStep1 from './Step1/PeopleDataWizardStep1.vue';
import ContentStep2 from './Step2/PeopleDataWizardStep2.vue';
import ContentStep3 from './Step3/PeopleDataWizardStep3.vue';
import ContentStep4 from './Step4/PeopleDataWizardStep4.vue';

export default {
    components: {
        ValidationObserver,
        FadeTransition,
        AppLoader,
        PeopleDataWizardLeftSideBarComponent,
        ContentStep1,
        ContentStep2,
        ContentStep3,
        ContentStep4
    },

    created(){
        this.getData();
    },
    
    data() {
        return {
            step: 1,

            item_data: {
                step: 0,
                applicant_type: 1,
                sex: null
            },

            loading: false,
            loading_next_step: false,
            loading_partial: false,
            show_success_alert: false,
            show_unsuccess_alert: false,
            show_email_alert: false,
        };
    },
    
    
    methods: {
       getData(){

           this.loading = true;

           axios
                .get("people/data-wizard/getData/" + this.$route.params.token)
                .then((res) => {  
                    
                    this.item_data = res.data;
                    if (this.item_data.zzp_company_features != null)
                        this.item_data.zzp_company_features = JSON.parse(this.item_data.zzp_company_features);
                    this.step = res.data.step;                  
                    
                })
                .catch((error) => {
                   console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });  
       },

       goBack(){
            this.loading = true;

            axios
                .get("people/data-wizard/goBack/" + this.$route.params.token)
                .then((res) => {
                    this.item_data = res.data;
                    if (this.item_data.zzp_company_features != null)
                        this.item_data.zzp_company_features = JSON.parse(this.item_data.zzp_company_features);
                    this.step = this.item_data.step;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async saveStep(go_forward){
            if(go_forward == 1){


                if(this.step === 1){// krok pierwszy sprawdzanie poprawnosci adresu i BIG

                    if(this.item_data.address.type === 1 && (this.item_data.address.city === null || this.item_data.address.city == "")
                                                                && (this.item_data.address.street === null || this.item_data.address.street == "")){
                        return false;
                    }
                    
                    if(this.item_data.big.is_big_number === 1 && this.item_data.big.big_data_main === null){
                        return false;
                    }

                }else if(this.step === 2){// krok drugi sprawdzenie poprawnosci KVK

                    if(this.item_data.applicant_type === undefined || this.item_data.applicant_type === null || this.item_data.applicant_type < 1){
                        return false;
                    }

                    if((this.item_data.applicant_type == 2 && this.item_data.kvk_data_main == null) || (this.item_data.applicant_type == 2 && this.item_data.kvk_data_main != null && JSON.parse(this.item_data.kvk_data_main).kvk_number != this.item_data.kvk_number)){
                        return false;
                    }

                    if((this.item_data.applicant_type == 2 && this.item_data.btw_data_main == null) || (this.item_data.applicant_type == 2 && this.item_data.btw_data_main != null && this.item_data.btw_data_main.btw_number != this.item_data.btw_number)){
                        return false;
                    }

                }

                this.loading_next_step = true;
            }

            if (!await this.checkEmail()){
                return false;
            }

            this.loading = true;

            let form_data = new FormData();

            if(this.step == 1){//zapisanie kroku 1 
                
                form_data.append("item_data", JSON.stringify(this.item_data));
                form_data.append("go_forward", go_forward);


                axios
                    .post("people/data-wizard/saveStepOne/" + this.$route.params.token,  form_data)
                    .then((res) => {
                        this.item_data = res.data;
                        if (this.item_data.zzp_company_features != null)
                            this.item_data.zzp_company_features = JSON.parse(this.item_data.zzp_company_features);
                        this.step = res.data.step;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;
                        
                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
                        
                    });

            }else if(this.step == 2){//zapisanie kroku 2 
                this.item_data.zzp_company_features = JSON.parse(JSON.stringify(this.item_data.zzp_company_features));
                
                form_data.append("go_forward", go_forward);
                form_data.append("item_data", JSON.stringify(this.item_data));

                axios
                    .post("people/data-wizard/saveStepTwo/" + this.$route.params.token,  form_data)
                    .then((res) => {
                        
                        this.item_data = res.data;
                        if (this.item_data.zzp_company_features != null)
                            this.item_data.zzp_company_features = JSON.parse(this.item_data.zzp_company_features);
                        this.item_data.zzp_company_features_status = 1;
                        this.step = res.data.step;  
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;

                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                    behavior: "smooth",
                                                                    block: "start",
                                                                });
                        
                    });

            }else if(this.step == 3){//zapisanie kroku 3

                form_data.append("go_forward", go_forward);
                form_data.append("item_data", JSON.stringify(this.item_data));

                axios
                    .post("people/data-wizard/saveStepThree/" + this.$route.params.token,  form_data)
                    .then((res) => {
                        
                        this.item_data = res.data;
                        this.step = res.data.step;
                        
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                        this.loading_next_step = false;
                        this.show_unsuccess_alert = !this.show_unsuccess_alert;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loading_next_step = false;

                        if(go_forward == 0){
                            this.show_success_alert = !this.show_success_alert;
                        }

                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                        
                    });

            }

            
        },

        async nextStep(){

            if (!await this.checkEmail()){
                return false;
            }
            
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.form.errors)
                            .map(([key, value]) => ({
                                key,
                                value,
                            }))
                            .filter((error) => {
                                return !error || !error.value || error.value.length > 0;
                            });
                            
                        if (errors && errors.length > 0) {

                            errors.forEach(error => {
                                    if(error.key == 'IBAN' || error.key == 'BIC'){
                                        this.$refs.form.setErrors({"bank_data":[this.trans('invalid-iban',256)]});

                                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                                    }
                                    if(error.key == 'BSN nummer'){
                                        this.$refs.form.setErrors({"id_data":[this.trans('invalid-bsn',256)]});

                                        document.getElementById('app').scrollIntoView({
                                                                behavior: "smooth",
                                                                block: "start",
                                                            });
                                    }

                                    if(error.key == 'Functie' || error.key == 'Organisatie' || error.key == 'Voornaam' || error.key == 'Achternaam' || error.key == 'Telefoonnummer' || error.key == 'Email' || error.key == 'email_phone'){
                                        this.$refs.form.setErrors({"reference_data":[this.trans('reference-required',256)]});

                                        if(this.$refs.form.refs[errors[0]["key"]] != null){
                                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                            });
                                        }else{
                                            this.$refs.form.refs['Telefoonnummer'].$el.scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                            });
                                        }  
                                                }
                            })

                            if(this.$refs.form.refs[errors[0]["key"]] != null){
                                this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }else{
                                this.$refs.form.refs['Telefoonnummer'].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                });
                            }   
                        }
                    }, 100);

                    return false;
                }
                
                this.saveStep(1);
            });

        },

        async checkEmail(){
            let result = true;

            if (!this.item_data?.person_data) {
                return true;
            }

            if (this.item_data.person_data.email == null || this.item_data.person_data.email == ""){
                return true;
            }

            await axios
                .post("people/data-wizard/checkEmail/" + this.$route.params.token, {email: this.item_data.person_data.email})
                .then((res) => {
                    if (res.data === 0){
                        this.show_email_alert = true;
                        result = false;
                    } else {
                        this.show_email_alert = false;
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    result = false;
                });
            return result;
        }
    },

    

};
</script>

<style scoped>

</style>
