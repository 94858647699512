<template>
    <div>
        <b-card class="mb-3">
            <div class="app-card-profile-title mb-1">{{trans('pdw-zzp-data',234)}}</div>
            <div class="mx-3 mt-3">

                <app-wizard-kvk :applicationData.sync="itemData" :idItem="cItemData.id_person_data_main" :isNotRequired="1" :itemType="1" />

                <app-row-left-label :slots="!is_mobile.value ? [6,6] : [12]" :isMarginBetween="false">
                    
                    <label class="app-input-top-label">{{trans('pdw-enter-btw',234)}}</label>
                    <app-btw ref="btw_control" :id_item="parseInt(cItemData.id_person_data_main)" :btwData.sync="cItemData" :validatorRules="'required'"/>

                    <template v-slot:col-1>
                        <div class="app-input-top-label mt-2">{{trans('pdw-enter-btw',234)}} <label><img id="popover-target-1" tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top; margin-top: -1px;" class="cursor-pointer ml-1" /> </label></div>
                    </template>
                    <template v-slot:col-2>
                        <!--<app-input id="vi-btw-number" v-model="cItemData.btw_number" validatorName="BTW nummer" validatorRules="btw-number"  mask="BTW" :uppercase="true" /> -->
                        <app-btw ref="btw_control" :id_item="parseInt(cItemData.id_person_data_main)" :btwData.sync="cItemData" :validatorRules="'required'"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :slots="!is_mobile.value ? [6,6] : [12]" :isMarginBetween="false">
                    <!--<app-input :label="trans('pdw-registration-lrza',234)" v-model="cItemData.wtza_number" validatorName="Registratienummer WTZA" validatorRules="digits:8" type="integer_08" v-if="is_mobile.value" /> -->
                    <label class="app-input-top-label">{{trans('pdw-registered-in-lrza-register',234)}}</label>
                    <app-check-box :label="trans('pdw-registration-lrza',234)" id="lrza_checkbox" v-model="cItemData.lrza" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                    <template v-slot:col-1>
                        <!--<div class="app-input-top-label mt-2">{{trans('pdw-registration-lrza',234)}}</div>-->
                        <div class="app-input-top-label mt-2">{{trans('pdw-registered-in-lrza-register',234)}}</div>
                    </template>
                    <template v-slot:col-2>
                        <!--<app-input v-model="cItemData.wtza_number" validatorName="Registratienummer WTZA" validatorRules="digits:8" type="integer_08" /> -->
                        <app-check-box id="lrza_checkbox" v-model="cItemData.lrza" class="custom-control-primary mr-4" value="1" unchecked-value="0" size="lg" validatorRules="required" :validatorCustomMessage="{ required: trans('required-field',256) }" />
                    </template>
                </app-row-left-label>

                <b-popover target="popover-target-1" triggers="focus" no-fade custom-class="app-local-popover">
                        <template #title>
                            {{trans('information',257)}}
                        </template>
                        <p class="app-popover-text" v-html="trans('pdw-btw-hint-information',234)"></p>
                </b-popover>
            </div>
        </b-card>

        <app-collapse accordion type="margin" class="mb-3">

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-bank-details',234)" :id="'item8'">
                    <people-applicants-profile-wizard-bank-details-component :itemData="itemData" :refresh_bank_error.sync="refresh_bank_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_bank_error" #default="{ errors }" name="Bankpas" :vid="'bank_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>


            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je vereiste documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item1'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pdw-diplomas',234)}}</span>
                        <span class="text-muted ml-1" v-if="[2,21,14,12,69].includes(itemData.id_function_data_main)"> {{trans('pdw-optional',234)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="3" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_diploma" />
                </app-collapse-item>
                <validation-provider :rules="(itemData.document_diploma.status == 1 || [2,21,14,12,69].includes(itemData.id_function_data_main)) ? '' : 'required'" #default="{ errors }" name="Diploma('s)">
                    <small class="text-danger ml-1" v-if="itemData.document_diploma.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-declaration-of-conduct',234)" :id="'item2'">
                    <small class="text-muted">{{trans('pdw-vog-description',234)}}</small>
                    <app-radio-group v-model="cItemData.is_vog_document" :options="vog_option" :stacked="true" class="app-local-radio-vog mt-3"/>
                    <people-applicants-profile-wizard-document-component class="mt-4" v-if="cItemData.is_vog_document != null && cItemData.is_vog_document == 1" :id_document_const_type="9" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_verklaring" />
                </app-collapse-item>

                <validation-provider :rules="cItemData.is_vog_document != null ? '' : 'required'" v-if="cItemData.is_vog_document == null"  #default="{ errors }" :custom-messages="{ required: trans('required-field',256) }">
                    <small class="text-danger ml-1"  v-if="cItemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>

                <validation-provider :rules="(cItemData.document_verklaring.status == 1 || cItemData.is_vog_document == null || cItemData.is_vog_document != 1)  ? '' : 'required'" v-if="cItemData.is_vog_document != 0"   #default="{ errors }" name="Verklaring omtrent gedrag">
                    <small class="text-danger ml-1" v-if="cItemData.document_verklaring.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-dispute-commission',234)" :id="'item4'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="5" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_disputes" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_disputes.status == 1 ? '' : 'required'" #default="{ errors }" name="Geschillencommissie">
                    <small class="text-danger ml-1" v-if="itemData.document_disputes.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" :title="trans('pdw-professional-liability',234)" :id="'item5'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="6" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_liability" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_liability.status == 1 ? '' : 'required'" #default="{ errors }" name="Bedrijfsaansprakelijksheidverzekering">
                    <small class="text-danger ml-1" v-if="itemData.document_liability.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="Ondernemersprofiel" :id="'item11'">
                    <people-applicants-profile-wizard-company-component :itemData="itemData" :refresh_company_error.sync="refresh_company_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_company_error" #default="{ errors }" :rules="itemData.zzp_company_features.question_1 != null && itemData.zzp_company_features.question_2 != null && itemData.zzp_company_features.question_3 != null && itemData.zzp_company_features.question_6 != null ? '' : 'required'"name="Ondernemersprofiel">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="CV" :id="'item6'">
                    <people-applicants-profile-wizard-document-component :id_document_const_type="7" :maxFilesNumber="1" :itemData="itemData" :document="itemData.document_cv" accept_files="application/msword, application/pdf" />
                </app-collapse-item>
                <validation-provider :rules="itemData.document_cv.status == 1 ? '' : 'required'" #default="{ errors }" name="CV">
                    <small class="text-danger ml-1" v-if="itemData.document_cv.status != 1 && errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>
            
            <b-card no-body class="app-card-wizard mb-3">
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item10'">
                    <template v-slot:header>
                        <span class="lead collapse-title mr-1">{{trans('pdw-reference',234)}}</span>
                        <img id="popover-target-2" tabindex="0" src="@/assets/images/icons/info-button.svg" height="18px" style="vertical-align: text-top;" class="cursor-pointer" @click="showReferenceInfo" />
                    </template>
                    <people-applicants-profile-wizard-reference-component :itemData="itemData" :refresh_reference_error.sync="refresh_reference_error"/>
                </app-collapse-item>
                <validation-provider :key="refresh_reference_error" #default="{ errors }" name="Referentie" :vid="'reference_data'">
                    <small class="text-danger ml-1" v-if="errors[0] != null">{{ errors[0] }}</small>
                </validation-provider>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">

                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item3'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pdw-quality-register',234)}}</span>
                        <span class="text-muted ml-1"> {{trans('pdw-optional',234)}} </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="4" :maxFilesNumber="3" :itemData="itemData" :document="itemData.document_quality" />
                </app-collapse-item>
            </b-card>

            <b-card no-body class="app-card-wizard mb-3">
                <!--<div class="app-card-profile-title">Upload hier je optionele documenten</div>-->
                <app-collapse-item style="box-shadow:none !important;" title="" :id="'item7'">
                    <template v-slot:header>
                        <span class="lead collapse-title">{{trans('pdw-certificates',234)}}</span>
                        <span class="text-muted ml-1"> {{trans('pdw-optional',234)}}  </span>
                     </template>
                    <people-applicants-profile-wizard-document-component :id_document_const_type="10" :maxFilesNumber="20" :itemData="itemData" :document="itemData.document_certificate" />
                </app-collapse-item>
                <small class="text-danger ml-1"></small>
            </b-card>
        </app-collapse>

        <b-popover target="popover-target-2" triggers="focus" no-fade>
            <template #title>
                {{trans('pdw-reference',234)}}
            </template>
            <p class="app-popover-text">{{trans('pdw-reference-info', 234)}}</p>
        </b-popover>
    </div>
</template>
<script>
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";
import { ValidationProvider } from "vee-validate";
import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue';
import PeopleApplicantsProfileWizardDocumentLiabilityComponent from './PeopleApplicantsProfileWizardDocumentLiabilityComponent.vue';
import AppWizardKvk from '@core/components/Kvk/AppWizardKvk.vue';
import PeopleApplicantsProfileWizardBankDetailsComponent from './PeopleApplicantsProfileWizardBankDetailsComponent.vue';
import PeopleApplicantsProfileWizardReferenceComponent from './PeopleApplicantsProfileWizardReferenceComponent.vue';
import PeopleApplicantsProfileWizardCompanyComponent from './PeopleApplicantsProfileWizardCompanyComponent.vue';
import AppBtw from "@core/components/AppBtw"

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        ValidationProvider,
        PeopleApplicantsProfileWizardDocumentComponent,
        AppWizardKvk,
        PeopleApplicantsProfileWizardBankDetailsComponent,
        PeopleApplicantsProfileWizardReferenceComponent,
        PeopleApplicantsProfileWizardDocumentLiabilityComponent,
        PeopleApplicantsProfileWizardCompanyComponent,
        AppBtw
    },

    props: ["itemData", "substeps"],

    created() {
        if(this.cItemData.lrza == '0'){
            this.cItemData.lrza = null;
        }
    },

    watch:{
        "cItemData.lrza": {
            handler: function(val) {
                if(this.cItemData.lrza == '0')
                    this.cItemData.lrza = null;
            },
        },
    },

    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        }
    },
    
    data() {
        return {
            option: [
                { value: 1, text: this.trans('yes-option',253) },
                { value: 0, text: this.trans('no-option',253) },
            ],
            vog_option: [
                { value: 1, htmltext: this.trans('pdw-add-vog',234) },
                { value: 0, htmltext: this.trans('pdw-apply-for-vog',234) },
            ],

            document_type:[
                { value: 1, text: this.trans('pdw-passport',234) },
                { value: 2, text: this.trans('pdw-id',234) },
                { value: 3, text: this.trans('pdw-residence-document',234) },
            ],
            refresh_bank_error: 0,
            refresh_reference_error: 0,
            refresh_company_error: 0,
        };
    },

    methods: {
        showReferenceInfo(event) {
            event.stopPropagation();
            this.$root.$emit("bv::show::popover", "popover-target-2");
        },
    }
    
};
</script>

<style>
[class*="collapse-"] .card .card-body{
    padding: 1.5rem;   
}

.app-local-radio-vog .custom-control-label {
    height: auto !important
}
</style>
<style scoped>
.app-local-popover{
    width: calc(100vw - 60px);
}
</style>
