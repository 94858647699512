<template>
    <div>
        <app-row-left-label :slots="!is_mobile.value ? [6, 6] : [12]" :isMarginBetween="false" class="mt-2 mb-2">
            <app-input v-model="cItemData.iban" uppercase :label="cIbanLabel"  :additionalInfo="trans('pdw-on-front-of-bank-card',234)" validatorRules="required|eleven-proof" validatorName="IBAN" mask="lettersE3-32" v-if="is_mobile.value" @change="checkIban()"/>
            <template v-slot:col-1>
                <app-input v-model="cItemData.iban" uppercase :label="cIbanLabel"  :additionalInfo="trans('pdw-on-front-of-bank-card',234)" validatorRules="required|eleven-proof" validatorName="IBAN" mask="lettersE3-32" @change="checkIban()"/>
            </template>
        </app-row-left-label>

        <app-row-left-label v-if="cIbanNl" :slots="!is_mobile.value ? [6, 6] : [12]" :isMarginBetween="false" class="mt-2 mb-2">
            <app-input v-model="cItemData.iban_bic_code" uppercase :label="trans('pdw-bic', 234)" validatorRules="required|bic-number" :validatorName="trans('pdw-bic', 234)" mask="lettersE3-11" @change="checkBic()"/>
            <template v-slot:col-1>
                <app-input v-model="cItemData.iban_bic_code" uppercase :label="trans('pdw-bic', 234)" validatorRules="required|bic-number" :validatorName="trans('pdw-bic', 234)" mask="lettersE3-11" @change="checkBic()"/>
            </template>
        </app-row-left-label>

    </div>
    
</template>
<script>

import PeopleApplicantsProfileWizardDocumentComponent from './PeopleApplicantsProfileWizardDocumentComponent.vue'

export default {

    components: {
        PeopleApplicantsProfileWizardDocumentComponent
    },
    
    props:{
        itemData: Object,
        refresh_bank_error: {}

    },

    created(){

    },


    computed: {
        cItemData:{
            get() { return this.itemData },
            set(value) { this.$emit("update:itemData", value) }
        },

        cRefreshBankError:{
            get() { return this.refresh_bank_error },
            set(value) { this.$emit("update:refresh_bank_error", value) }
        },

        cIbanLabel() {
            if(this.itemData.applicant_type == 1){//UZK
                return this.trans('pdw-enter-bank-account',233);
            }else if(this.itemData.applicant_type == 2){//ZZP
                return this.trans('pdw-enter-business-bank-account',233);
            }else{
                return this.trans('pdw-enter-iban',233);
            }
        },
        cIbanNl: {
            get(){
                if(this.itemData.iban != null && this.itemData.iban != ''){
                    return !this.itemData.iban.startsWith('NL');
                } else {
                    return false;
                }
            }
        }
    },

    data(){
        return{
        
        }
    },

    methods: {
        checkIban(){
            let value = this.cItemData.iban.toUpperCase().replace(/\s/g, "");
            let rearrange = value.substring(4, value.length) + value.substring(0, 4);
            let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let alphaMap = {};
            let number = [];

            alphabet.forEach((value, index) => {
                alphaMap[value] = index + 10;
            });
            rearrange.split("").forEach((value, index) => {
                number[index] = alphaMap[value] || value;
            });
            if (this.modulo(number.join("").toString(), 97) === 1) {
                this.cRefreshBankError++;
            }

        },

        checkBic(){
            if( /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(this.cItemData.iban_bic_code)){
                this.cRefreshBankError++;
            }
        },

        modulo(aNumStr, aDiv){
            var tmp = "";
            var i, r;
            for (i = 0; i < aNumStr.length; i++) {
                tmp += aNumStr.charAt(i);
                r = tmp % aDiv;
                tmp = r.toString();
            }
            return tmp / 1;
        }
    }

}
</script>
<style scoped>
.app-local-margin{
    margin-bottom: 10px !important;
}
</style>